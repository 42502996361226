import React from "react";

import { Col, Row } from "react-bootstrap";

export interface SiteDisplayProps {
  phone: string;
  laptop: string;
  title: string;
  text: string;
  link: string;
  inverse?: boolean;
  noText?: boolean;
}

const SiteDisplay: React.FC<SiteDisplayProps> = ({
  phone,
  laptop,
  title,
  link,
  text,
  inverse,
  noText,
}) => {
  return (
    <div
      style={{ maxHeight: "100vh" }}
      className="my-5">
      <Row className="col-lg-11 mx-auto ">
        {inverse ? (
          <>
            <Col
              xs={12}
              md={3}>
              <img
                style={{ maxHeight: "65vh", maxWidth: "100%" }}
                src={phone}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Col>
            <Col
              xs={12}
              md={9}>
              <img
                style={{ maxHeight: "65vh", maxWidth: "100%" }}
                src={laptop}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Col>
          </>
        ) : (
          <>
            <Col
              xs={12}
              md={9}>
              <img
                style={{ maxHeight: "65vh", maxWidth: "100%" }}
                src={laptop}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Col>
            <Col
              xs={12}
              md={3}>
              <img
                style={{ maxHeight: "65vh", maxWidth: "100%" }}
                src={phone}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Col>
          </>
        )}
      </Row>

      {!noText && (
        <Row className="mx-auto">
          <Col
            lg={11}
            style={{
              textAlign: !inverse ? "right" : "left",
              marginLeft: inverse ? "40px" : 0,
            }}
            className="mt-1">
            <a
              href={link ?? ""}
              style={{ textDecoration: "none", color: "black" }}
              target="_blank">
              <h4 style={{ fontWeight: "400", fontStyle: "italic" }}>
                {title}
              </h4>
            </a>
            <h6 style={{ fontWeight: "400" }}>{text}</h6>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SiteDisplay;
