import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading, View } from "typed-component-gallery";
import { sites } from "../widgets/sites";

export const CustomApp: React.FC = () => {
  return (
    <>
      <Row className="my-3">
        <Heading
          size={5}
          style={{ fontWeight: "100" }}>
          Custom Applications
        </Heading>
        <Heading
          size={3}
          style={{ fontWeight: "100" }}>
          Innovative Solutions for Complex Problems
        </Heading>
      </Row>
      <div className="mt-4">
        <Row
          className="col-lg-11 mx-auto "
          style={{}}>
          <Col
            xs={12}
            lg="9">
            <img
              style={{
                maxHeight: "65vh",
                maxWidth: "100%",
                objectFit: "contain",
                height: "auto",
              }}
              src={sites.realEstate.laptop}
              alt="Laptop display"
            />
          </Col>
          <Col
            xs={12}
            lg={3}>
            <View>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                We Develop Applications Tailored to Your Company, From Real
                Estate to Sporting Goods We Bring Your Vision to Life
              </Heading>
            </View>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            lg={9}>
            <div style={{ marginTop: "15vh" }}>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                Mobile First Design, SEO, Serverless Computing, and Cutting Edge
                AI
              </Heading>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                We Bring Them Together for an App That Sets You up
              </Heading>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                For Sucess in a Modern Digital Enviorment
              </Heading>
            </div>
          </Col>
          <Col
            xs={12}
            lg="3">
            <img
              style={{
                maxHeight: "65vh",
                maxWidth: "100%",
                objectFit: "contain",
                height: "auto",
              }}
              src={sites.realEstate.phone}
              alt="Phone display"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
