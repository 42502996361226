import { mdiHandshakeOutline } from "@mdi/js";
import Icon from "@mdi/react";

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import founding from "../assets/founding.png";
import pepes from "../assets/pepes.png";
export const About: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Container>
      <Row>
        <Heading
          size={4}
          style={{ fontWeight: "100" }}>
          About Us
        </Heading>
      </Row>
      <Row className="mb-5">
        <Row className="my-2">
          <Col lg={9}>
            <Heading
              size={2}
              align="left">
              Our Start
            </Heading>
            <Heading
              size={1}
              style={{ textAlign: "justify" }}>
              Data 26 was founded by Isaac Long, a passionate web developer and
              former student of the University of Pennsylvania. Isaac brings a
              wealth of knowledge and a unique perspective to every project,
              with a strong background in modern development emphasizing
              TypeScript and single-page applications, as well as legacy
              platforms like WordPress.
            </Heading>{" "}
            <Heading
              size={1}
              style={{ textAlign: "justify" }}>
              Before starting Data 26, Isaac worked as a scrum leader at Fortune
              Brands, a Fortune 500 company that owns a myriad of manufacturers
              of home and construction goods. After parting ways with the
              company due to corporate lay offs, Isaac saw an opportunity to
              pursue a new path. Seeking creative freedom and the ability to
              provide truly custom solutions for any web problem, he decided to
              venture out on his own and establish an independent firm.
            </Heading>
          </Col>
          <Col lg={3}>
            <img
              style={{ borderRadius: "10px" }}
              className="m-2"
              src={founding}
              height="200px"></img>
          </Col>
        </Row>
        <Row className="my-2">
          <Col lg={12}>
            <Heading
              size={2}
              align="left">
              Our Mission
            </Heading>
            <Heading
              size={1}
              style={{ textAlign: "justify" }}>
              Our mission at Data 26 is to help businesses establish a strong
              online presence, enabling them to connect with their audience in a
              meaningful and engaging way. We believe in the power of ideas and
              the importance of expressing those ideas through digital mediums.
            </Heading>
          </Col>
        </Row>
        <Row className="my-2">
          <Col lg={9}>
            <Heading
              size={2}
              align="left">
              Our Team
            </Heading>

            <Heading
              size={1}
              style={{ textAlign: "justify" }}>
              Our team is composed of seasoned professionals with diverse
              backgrounds in web design, development, and digital marketing. We
              leverage our collective expertise to create digital experiences
              that are not only visually stunning but also functional,
              user-friendly, and optimized for search engines. We pride
              ourselves on our commitment to excellence and our ability to
              deliver tailored solutions that meet the unique needs of our
              clients.
            </Heading>
          </Col>

          <Col
            lg={3}
            className="text-center">
            <img
              style={{ borderRadius: "10px" }}
              className="my-2 text-center"
              src={pepes}
              height="125px"></img>
          </Col>
        </Row>
        <Row className="my-2">
          <Col lg={12}>
            <Heading
              size={2}
              align="left">
              Our Approach
            </Heading>
            <Heading
              size={1}
              style={{ textAlign: "justify" }}>
              Our approach is collaborative and client-focused, ensuring that we
              understand your vision and goals and translate them into a digital
              platform that enhances your brand and drives growth. At Data 26,
              we utilize the latest technologies and adhere to industry best
              practices to deliver websites that are responsive, secure, and
              compatible with all devices. We are continually learning and
              evolving to stay at the forefront of the rapidly changing digital
              landscape.
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col lg={9}>
            <Heading
              size={2}
              align="left">
              Our Commitment
            </Heading>
            <Heading
              size={1}
              style={{ textAlign: "justify" }}>
              Our commitment to quality, our attention to detail, and our
              passion for helping businesses succeed online are what set us
              apart. We look forward to partnering with you to create a website
              that not only meets but exceeds your expectations.
            </Heading>
          </Col>
          <Col
            lg={3}
            className="text-center">
            <Icon
              path={mdiHandshakeOutline}
              style={{ color: theme.colors[0] }}
              size={5}></Icon>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};
