import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading, View } from "typed-component-gallery";
import { sites } from "../widgets";

export const EloiseStudent: React.FC = () => {
  return (
    <>
      <Row>
        <Heading
          size={5}
          style={{ fontWeight: "100" }}>
          Introducing Eloise
        </Heading>
        <Heading
          size={3}
          style={{ fontWeight: "100" }}>
          Coming Fall of '23
        </Heading>
      </Row>
      <div className="mt-4">
        <Row
          className="col-lg-11 mx-auto "
          style={{}}>
          <Col
            xs={12}
            lg="9">
            <img
              style={{
                maxHeight: "65vh",
                maxWidth: "100%",
                objectFit: "contain",
                height: "auto",
              }}
              src={sites.eloise.laptop}
              alt="Laptop display"
            />
          </Col>
          <Col
            xs={12}
            lg={3}>
            <View>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                The Power of Generative Text AI In an Easy to Use Package
                Tailored to Students and Teachers
              </Heading>
            </View>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            lg={9}>
            <div style={{ marginTop: "15vh" }}>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                Modern AI + Contextual Recollection
              </Heading>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                The Result
              </Heading>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                A Personal Assistant Like The World Has Never Seen
              </Heading>
            </div>
          </Col>
          <Col
            xs={12}
            lg="3">
            <img
              style={{
                maxHeight: "65vh",
                maxWidth: "100%",
                objectFit: "contain",
                height: "auto",
              }}
              src={sites.eloise.phone}
              alt="Phone display"
            />
          </Col>
        </Row>
        {/* Rest of the component */}
      </div>
    </>
  );
};
