import { Theme } from "typed-component-gallery";

export const themeConfig: Theme = {
  colors: [
    "hsla(238, 52%, 63%, 1)",
    "hsla(4, 100%, 68%, 1)",
    "hsla(5, 88%, 57%, 1)",
    "hsla(40, 100%, 67%, 1)",
    "hsla(40, 100%, 67%, 1)",
  ],
  white: "hsla(90, 60%, 96%, 1)",
  font: "roboto",
  fontSize: "1.25px",
  borderRadius: "3px",
  border: "1px solid grey",

  mode: "light",
  input: {
    border: true,
    style: { border: "1px solid black" },
    rounded: true,
  },
};
