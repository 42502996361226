import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading, View } from "typed-component-gallery";
import { sites } from "../widgets/sites";

export const Ecomm: React.FC = () => {
  return (
    <>
      <Row className="my-3">
        <Heading
          size={5}
          style={{ fontWeight: "100" }}>
          E-Commerce Development
        </Heading>
        <Heading
          size={3}
          style={{ fontWeight: "100" }}>
          Modern Platforms, Beautiful Designs
        </Heading>
      </Row>
      <div className="mt-4">
        <Row
          className="col-lg-11 mx-auto "
          style={{}}>
          <Col
            xs={12}
            lg="9">
            <img
              style={{
                maxHeight: "65vh",
                maxWidth: "100%",
                objectFit: "contain",
                height: "auto",
              }}
              src={sites.irt.laptop}
              alt="Laptop display"
            />
          </Col>
          <Col
            xs={12}
            lg={3}>
            <View>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                Using Modern Platforms Like Shopify We Build Proffesional Sites
                that are Easy to Manage Once We are Done
              </Heading>
            </View>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            lg={9}>
            <div style={{ marginTop: "15vh" }}>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                By Combining Popular Platforms With Proffesional Development
              </Heading>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                We are Able to Leave You With an Easy To Use Store With
              </Heading>
              <Heading
                size={3}
                style={{ fontWeight: "100" }}>
                Low Opperating Cost, Custom Features, and Proffesional Analytics
              </Heading>
            </div>
          </Col>
          <Col
            xs={12}
            lg="3">
            <img
              style={{
                maxHeight: "65vh",
                maxWidth: "100%",
                objectFit: "contain",
                height: "auto",
              }}
              src={sites.clamps.phone}
              alt="Phone display"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
