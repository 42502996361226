import { EloisePage } from "typed-component-gallery";
import { About, Contact, Gallery, Home } from "../components/pages";
import { CustomApp } from "../components/pages/customApps";
import { Ecomm } from "../components/pages/ecomm";
import { EloiseStudent } from "../components/pages/eloise";
import { TCG } from "../components/pages/tcg";

export const pages: EloisePage[] = [
  {
    name: "/",
    component: <Home />,
    noAuth: true,
  },
  {
    name: "About",
    noAuth: true,
    component: <About />,
  },
  {
    name: "Gallery",
    noAuth: true,
    component: <Gallery />,
  },

  {
    name: "Services",
    pages: [
      {
        name: "Custom Applications",
        noAuth: true,
        url: "custom-apps",
        component: <CustomApp />,
      },
      // {
      //   name: "Digital Marketing",
      //   noAuth: true,

      //   component: <Home />,
      // },
      {
        name: "E-Commerce Development",
        noAuth: true,
        url: "ecommerce",
        component: <Ecomm />,
      },
      // {
      //   name: "Social Media Management and Automation",
      //   noAuth: true,

      //   component: <Home />,
      // },
    ],
  },
  {
    name: "Software",
    pages: [
      {
        name: "Eloise",
        url: "eloise",
        noAuth: true,
        component: <EloiseStudent />,
      },
      {
        name: "Typed Component Gallery",
        url: "typed-component-gallery",
        noAuth: true,
        component: <TCG />,
      },
      // {
      //   name: "Array Methods",
      //   noAuth: true,

      //   component: <Home />,
      // },

      // {
      //   name: "TaskMaster",
      //   noAuth: true,

      //   component: <Home />,
      // },
    ],
  },
  {
    name: "Contact Us",
    noAuth: true,
    component: <Contact />,
  },
];
