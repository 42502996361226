import React from "react";

import { Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Heading, useEloise } from "typed-component-gallery";
import { SiteCarousel, sites } from "../widgets";

const Home: React.FC<{}> = () => {
  const navigate = useNavigate();

  const { logic } = useEloise();

  // useEffect(()=>{
  //     console.log(logic.fb.auth.currentUser.email)
  // },[])

  return (
    <div className="container-fluid px-0">
      <Row className="m-3">
        <Heading
          size={4}
          align="left"
          style={{ fontWeight: "100" }}>
          Thoughtful Design
        </Heading>
      </Row>
      <SiteCarousel sites={[sites.irt, sites.fiberon, sites.clamps]} />

      <div
        className="container-fluid mx-0"
        style={{ width: "100%", height: "100vh" }}></div>
    </div>
  );
};

export default Home;
