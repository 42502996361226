import React from "react";
import { Carousel } from "react-bootstrap";

import SiteDisplay, { SiteDisplayProps } from "./siteDisplay";

const SiteCarousel: React.FC<{
  sites: SiteDisplayProps[];
}> = ({ sites }) => {
  return (
    <Carousel interval={5000}>
      {sites.map((site: SiteDisplayProps, i: number) => (
        <Carousel.Item>
          <SiteDisplay {...site} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default SiteCarousel;
