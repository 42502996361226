import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  Heading,
  Input,
  LargeTextInput,
  useEloise,
} from "typed-component-gallery";

export const Contact: React.FC = () => {
  const [first, setFirst] = useState<string>();
  const [last, setLast] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [message, setMessage] = useState<string>("");

  const { logic } = useEloise();

  const validate = () => {
    if (
      ![first, last, email, phone].includes(undefined) &&
      message.length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (validate()) {
      //submit logic
      const ots = {
        first,
        last,
        email,
        phone,
        message,
      };

      let response = await logic.fb.docs.setBaseDoc(
        "/mainSite/contactForm",
        ots
      );
      if (response) {
        alert("message received");
        setFirst("");
        setLast("");
        setEmail("");
        setMessage("");
        setPhone("");
      }
    } else {
      alert("Please complete form to submit");
    }
  };

  const { theme } = useEloise();
  return (
    <Container>
      <Row>
        <Heading
          size={4}
          style={{ fontWeight: "100" }}>
          Contact Us
        </Heading>
      </Row>
      <Row>
        <Col
          lg={8}
          className="mx-auto"
          style={{ border: "1px solid black", borderRadius: "5px" }}>
          <Row className="my-3 mt-5 mx-auto">
            <Col
              lg={5}
              className="mx-auto">
              <Input
                label="First Name"
                state={first}
                setState={setFirst}
                extLabel
                rounded
              />
            </Col>
            <Col
              lg={5}
              className="mx-auto">
              <Input
                label="Last Name"
                state={last}
                setState={setLast}
                extLabel
                rounded
              />
            </Col>
          </Row>
          <Row className="my-3 mx-auto">
            <Col
              lg={5}
              className="mx-auto">
              <Input
                label="Email"
                state={email}
                setState={setEmail}
                extLabel
                rounded
              />
            </Col>
            <Col
              lg={5}
              className="mx-auto">
              <Input
                label="Phone"
                state={phone}
                setState={setPhone}
                extLabel
                rounded
              />
            </Col>
          </Row>
          <Row className="my-3 mx-auto">
            <Col
              lg={11}
              className="mx-auto">
              <LargeTextInput
                state={message}
                setState={setMessage}
                placeholder="Type your message here"></LargeTextInput>
            </Col>
          </Row>
          <Row className="my-3 ">
            <Col
              lg={5}
              className="mx-auto">
              <Button
                color={validate() ? theme.colors[0] : theme.colors[1]}
                onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
