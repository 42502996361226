import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  FileUpload,
  Heading,
  Input,
  LargeTextInput,
  View,
  useEloise,
} from "typed-component-gallery";
import tcgDisplay from "../assets/tcgDisplay.png";

export const TCG: React.FC = () => {
  const [input, setInput] = useState("");
  const { theme } = useEloise();
  return (
    <>
      <Row>
        <Heading
          size={5}
          style={{ fontWeight: "100" }}>
          Typed-Component-Gallery
        </Heading>
        <Heading
          size={3}
          style={{ fontWeight: "100" }}>
          An Open Source Library for Faster React Development
        </Heading>
      </Row>
      <Row className="m-4">
        <Col lg={2}>
          <Button color={theme.colors[1]}>Button</Button>
        </Col>
        <Col lg={2}>
          <Button color={theme.colors[0]}>Button</Button>
        </Col>
        <Col lg={2}>
          <Button style={{ backgroundColor: "#48A9A6" }}>Button</Button>
        </Col>
        <Col lg={2}>
          <Button color={theme.colors[2]}>Button</Button>
        </Col>
        <Col lg={2}>
          <Button color={theme.colors[3]}>Button</Button>
        </Col>
        <Col lg={2}>
          <Button style={{ backgroundColor: "hsla(173, 70%, 35%, 1)" }}>
            Button
          </Button>
        </Col>
      </Row>
      <Row className="m-4">
        <Col lg={3}>
          <Input
            style={{ border: "2px solid black" }}
            extLabel
            state={input}
            setState={setInput}
            label="Input"
          />
        </Col>
        <Col lg={3}>
          <Input
            extLabel
            style={{ border: "2px solid #6F73D2" }}
            state={input}
            setState={setInput}
            label="Input"
          />
        </Col>
        <Col lg={3}>
          <Input
            extLabel
            style={{ border: "2px solid #ffc759" }}
            state={input}
            setState={setInput}
            label="Input"
          />
        </Col>
        <Col lg={3}>
          <Input
            addy
            extLabel
            style={{ border: "2px solid #ff675c" }}
            state={input}
            setState={setInput}
            label="Input"
          />
        </Col>
      </Row>
      <Row className="m-4">
        <Col lg={5}>
          <LargeTextInput
            state={input}
            setState={setInput}
            placeholder="Large Text Input"
          />
        </Col>
        <Col lg={7}>
          <FileUpload path="test" />
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          lg={6}
          className="">
          <View>
            <Heading
              size={3}
              style={{ fontWeight: "100" }}>
              The Typed Component Gallery Brings Together Functional Design with
              Contextual Coding to Give Your App a Virtual Assistant with
              Intimate Knowledge of Your Site Right out of the Box
            </Heading>
          </View>
        </Col>
        <Col lg={6}>
          <img
            src={tcgDisplay}
            height={400}
          />
        </Col>
      </Row>
      <Row className="mt-4 mb-5">
        <a
          className="text-center"
          href="https://www.npmjs.com/package/typed-component-gallery"
          target="blank"
          style={{ fontWeight: "100", fontSize: "2rem" }}>
          Available on Npm Now
        </a>
      </Row>
    </>
  );
};
