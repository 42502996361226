import React from "react";

import SiteDisplay from "../widgets/siteDisplay";
import { sites } from "../widgets/sites";

const Gallery: React.FC<{}> = (props) => {
  return (
    <div className="container-fluid">
      {Object.keys(sites).map((site: string, i: number) => (
        <SiteDisplay
          {...sites[site]}
          inverse={i % 2 !== 0} //Odd indexs
        />
      ))}
    </div>
  );
};

export default Gallery;
